import React from 'react'

export default function PostCard ({ post }: {
  post: {
    html: string
    slug: string
    title: string
    canonical_url?: string
    feature_image?: string
    featured?: boolean
    tags?: [{name: string}]
    excerpt: string
    primary_author: {
      name: string
      profile_image?: string
    }
  }
}) {
  return (
    <div className="post-card">
      <a href={post.canonical_url}>
        <header className="post-card-header">
          {post.feature_image && (
            <div
              className="post-card-image"
              style={{
                backgroundImage: `url(${post.feature_image})`,
              }}
            />
          )}
          {post.featured && <span>Featured</span>}
        </header>
        <section
          className="post-card-excerpt"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </a>
    </div>
  )
}
