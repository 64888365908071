import React from 'react'
import Helmet from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'

import config from '../../utils/siteConfig'

import '../../styles/app.css'

function HeaderNavigation() {
  return (
    <div className="site-mast">
      <div className="site-mast-left">
        <Link to="/">Home</Link>
      </div>
    </div>
  )
}

function SiteNavigation() {
  return (
    <div className="site-mast">
      <div className="site-mast-left">
        <a
          className="site-nav-item"
          href="https://instagram.com/hangrybrain"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
        <a
          className="site-nav-item"
          href={`https://feedly.com/i/subscription/feed/${config.siteUrl}/rss/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          RSS
        </a>
        <div className="subscribe">
          <form
            action="https://tinyletter.com/hangrybrain"
            method="post"
            target="popupwindow"
            onSubmit={
              "window.open('https://tinyletter.com/hangrybrain', 'popupwindow', 'scrollbars=yes,width=800,height=600');return true" as any
            }
          >
            <label htmlFor="tlemail">Email updates</label>
            <input type="email" name="email" id="tlemail" />
            <input type="hidden" value="1" name="embed" />
            <input type="submit" value="Subscribe" />
          </form>
        </div>
      </div>
    </div>
  )
}

export interface DefaultLayoutProps {
  bodyClass?: string
  isHome?: boolean
}

function DefaultLayout({
  data: siteData,
  children,
  bodyClass,
  isHome,
}: React.PropsWithChildren<DefaultLayoutProps> & {
  data: GatsbyTypes.GhostSettingsQuery
}) {
  const site = siteData.allGhostSettings.edges[0].node

  return (
    <>
      <Helmet>
        <html lang={site.lang} />
        <style type="text/css">{`${site.codeinjection_styles}`}</style>
        <body className={bodyClass} />
        {isHome ? <title>{`${site.title} – ${site.description}`}</title> : null}
      </Helmet>

      <div className="viewport">
        <div className="viewport-top">
          {/* The main header section on top of the screen */}
          <header className="site-head">
            <div className="container">
              {isHome ? null : HeaderNavigation()}
              {isHome ? (
                <div className="site-banner">
                  <h1 className="site-banner-title">{site.title}</h1>
                  <p className="site-banner-desc">
                    The food and drink writing of{' '}
                    <Link to={'/about'}>Paul Melnikow</Link>
                  </p>
                </div>
              ) : null}
            </div>
          </header>

          <main className="site-main">{children}</main>

          <footer className="site-footer">
            <div className="container">
              <SiteNavigation />
            </div>
          </footer>
        </div>
      </div>
    </>
  )
}

const DefaultLayoutSettingsQuery = (
  props: React.PropsWithChildren<DefaultLayoutProps>
) => (
  <StaticQuery<GatsbyTypes.GhostSettingsQuery>
    query={graphql`
      query GhostSettings {
        allGhostSettings {
          edges {
            node {
              ...GhostSettingsFields
            }
          }
        }
        file(relativePath: { eq: "ghost-icon.png" }) {
          childImageSharp {
            fixed(width: 30, height: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => <DefaultLayout data={data} {...props} />}
  />
)

export default DefaultLayoutSettingsQuery
